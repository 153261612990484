.layout-body {
  width: 100%;
  background: #dcdddf59;
  padding:15px 15px 0px 15px;
  height: 100%;
  height: 100%;
  overflow-x: hidden;
}
@media (min-width: 1024px) {
  .layout-body {
    height: calc(90vh);
    
  }
  
}
