.active::before{
    content: "";
    width: 10px;
    height: 30px;
    border-left :1px  dotted #077DD3;
    border-bottom: 1px dotted #077DD3;
    position:absolute ;
    left: -10%;
    top: 0px;
  
    }
    .drawer {
        opacity: 0; /* Initially, drawer is transparent */
        height: 0; /* Initially, drawer has no height */
        overflow: hidden; /* Hide any content overflow */
        width: 250px;
        transition: opacity 0.3s ease, height 0.3s ease,width 0.3s ease; /* Transition opacity and height */
      }
      
      .open {
        opacity: 1; /* Drawer opened */
        height: auto; /* Allow the drawer to expand to its natural height */
        width: 250px;
        transition: opacity 0.3s ease, height 0.3s ease,width 0.3s ease; /* Transition opacity and height */
      }
      
      .closed {
        opacity: 0; /* Drawer closed */
        height: 0; /* Drawer collapses to no height */
        width: 0px;
        transition: opacity 0.3s ease, height 0.3s ease,width 0.3s ease; /* Transition opacity and height */
      }
      